import React, { Component } from 'react';

export default class EditExercises extends Component {

    render() {

        return (
            <div> <p>You are on the Edit Excercise component</p></div>
        )
    }

}


