import React, { Component } from 'react';

export default class ExercisesList extends Component {

    render() {

        return (
            <div> <p>You are on the Exercises List component..</p></div>
        )
    }

}


